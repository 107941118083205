<template>
    <div />
</template>
<script>
import { logout } from '../auth/auth0.js'
export default {
    name: 'LogoutCallback',
    created () {
        const language = this.$route.query.language ? this.$route.query.language : 'en'
        logout(language)
    }
  }
</script>
