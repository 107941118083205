<template>
    <div />
</template>
<script>
export default {
    name: 'Logout',

    created () {
        const language = this.$route.query.language ? this.$route.query.language : 'en'
        this.$router.replace({ name: 'home', params: { lang: language } })
    }
  }
</script>
